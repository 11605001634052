@font-face {
  font-family: 'Roboto';
  src: url('../shared/fonts/roboto/roboto-thin-webfont.woff2') format('woff2'),
    url('../shared/fonts/roboto/roboto-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../shared/fonts/roboto/roboto-black-webfont.woff2') format('woff2'),
    url('../shared/fonts/roboto/roboto-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../shared/fonts/roboto/roboto-bold-webfont.woff2') format('woff2'),
    url('../shared/fonts/roboto/roboto-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../shared/fonts/roboto/roboto-light-webfont.woff2') format('woff2'),
    url('../shared/fonts/roboto/roboto-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../shared/fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
    url('../shared/fonts/roboto/roboto-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../shared/fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
    url('../shared/fonts/roboto/roboto-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../shared/fonts/proxima/ProximaNova-ThinIt.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../shared/fonts/proxima/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../shared/fonts/proxima/ProximaNova-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../shared/fonts/proxima/ProximaNova-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../shared/fonts/proxima/ProximaNova-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../shared/fonts/proxima/ProximaNova-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Martian Mono';
  font-weight: 300;
  font-style: normal;
  src: url('../shared/fonts/martian/MartianMono-Variable.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  src: url('../shared/fonts/inter/Inter-Variable.ttf');
}

main {
  padding: 0;
}

#root {
  height: 100%;
  width: 100%;
}

body, html {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: inherit;
  box-sizing: border-box;
}