@import '../settings/variable';

.drawer-paper {
  width: 240px;
}

.MuiDrawer-paperAnchorLeft.drawer-paper-anchor {
  top: 66px;
  border-right: 0;
}

.topbar {
  width: 100%;
  position: fixed;
  top: 0;
  height: 61px;
  z-index: 101;

  @include themify($themes) {
    background: themed('colorBackground');
  }
}

.topbar__wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-top: 1px;
}

.topbar__button {
  width: 60px;
  height: 60px;
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {

    @include themify($themes) {
      background: themed('colorHover');
    }
  }

  &.topbar__button--desktop {
    display: none;
  }
}

.topbar__button-icon {
  margin: auto;
  transition: all 0.3s;
  width: 16px;
}

.form_logo {
  @include themify($themes) {
    background-image: themed('formLogo');
    background-size: 70%;
  }
}

.form_logo_finoa {
  @include themify($themes) {
    background-image: themed('logoImgFinoa');
  }
}

.topbar__logo,
.topbar__logo_finoa,
.topbar__logo_okcoin {
  max-width: 271.69px;
  width: 100%;
  height: 46px;
  margin: auto 0;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  display: none;
}

.topbar__logo {
  @include themify($themes) {
    background-image: themed('logoImg');
  }
}

.topbar__logo_okcoin {
  @include themify($themes) {
    background-image: themed('logoImgOkcoin');
  }
}

.topbar__logo_finoa {
  @include themify($themes) {
    background-image: themed('logoImgFinoa');
  }
}

.bnb_bsc__icon,
.algo__icon,
.paxg__icon,
.mana__icon,
.sand__icon,
.oxt__icon,
.band__icon,
.cro__icon,
.amp__icon,
.lrc__icon,
.ocean__icon,
.fet__icon,
.bal__icon,
.knc__icon,
.nmr__icon,
.ht__icon,
.nexo__icon,
.ftm__icon,
.cel__icon,
.aave__icon,
.grt__icon,
.crv__icon,
.bat__icon,
.yfi__icon,
.sushi__icon,
.bnt__icon,
.zrx__icon,
.uma__icon,
.etc__icon,
.sol__icon,
.matic__icon,
.bch__icon,
.bnb__icon,
.btc__icon,
.ceth__icon,
.dai__icon,
.dot__icon,
.enj__icon,
.eth__icon,
.link__icon,
.ltc__icon,
.mkr__icon,
.snx__icon,
.steth__icon,
.uni__icon,
.usdc__icon,
.usdt__icon,
.xml__icon,
.xrp__icon,
.comp__icon {
  width: 25px;
  height: 25px;
  margin: auto 5px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.bnb_bsc__icon {
  @include themify($themes) {
    background-image: themed('bnbBscIcon');
  }
}

.bch__icon {
  @include themify($themes) {
    background-image: themed('bchIcon');
  }
}

.bnb__icon {
  @include themify($themes) {
    background-image: themed('bnbIcon');
  }
}

.btc__icon {
  @include themify($themes) {
    background-image: themed('btcIcon');
  }
}

.ceth__icon {
  @include themify($themes) {
    background-image: themed('cethIcon');
  }
}

.dai__icon {
  @include themify($themes) {
    background-image: themed('daiIcon');
  }
}

.dot__icon {
  @include themify($themes) {
    background-image: themed('dotIcon');
  }
}

.enj__icon {
  @include themify($themes) {
    background-image: themed('enjIcon');
  }
}

.eth__icon {
  @include themify($themes) {
    background-image: themed('ethIcon');
  }
}

.link__icon {
  @include themify($themes) {
    background-image: themed('linkIcon');
  }
}

.ltc__icon {
  @include themify($themes) {
    background-image: themed('ltcIcon');
  }
}

.mkr__icon {
  @include themify($themes) {
    background-image: themed('mkrIcon');
  }
}

.snx__icon {
  @include themify($themes) {
    background-image: themed('snxIcon');
  }
}

.steth__icon {
  @include themify($themes) {
    background-image: themed('stethIcon');
  }
}

.matic__icon {
  @include themify($themes) {
    background-image: themed('maticIcon');
  }
}

.uni__icon {
  @include themify($themes) {
    background-image: themed('uniIcon');
  }
}

.usdc__icon {
  @include themify($themes) {
    background-image: themed('usdcIcon');
  }
}

.usdt__icon {
  @include themify($themes) {
    background-image: themed('usdtIcon');
  }
}

.xml__icon {
  @include themify($themes) {
    background-image: themed('xmlIcon');
  }
}

.xrp__icon {
  @include themify($themes) {
    background-image: themed('xrpIcon');
  }
}

.comp__icon {
  @include themify($themes) {
    background-image: themed('compIcon');
  }
}

.etc__icon {
  @include themify($themes) {
    background-image: themed('etcIcon');
  }
}

.sol__icon {
  @include themify($themes) {
    background-image: themed('solIcon');
  }
}

.aave__icon {
  @include themify($themes) {
    background-image: themed('aaveIcon');
  }
}

.grt__icon {
  @include themify($themes) {
    background-image: themed('grtIcon');
  }
}

.crv__icon {
  @include themify($themes) {
    background-image: themed('crvIcon');
  }
}

.bat__icon {
  @include themify($themes) {
    background-image: themed('batIcon');
  }
}

.yfi__icon {
  @include themify($themes) {
    background-image: themed('yfiIcon');
  }
}

.sushi__icon {
  @include themify($themes) {
    background-image: themed('sushiIcon');
  }
}

.bnt__icon {
  @include themify($themes) {
    background-image: themed('bntIcon');
  }
}

.zrx__icon {
  @include themify($themes) {
    background-image: themed('zrxIcon');
  }
}

.uma__icon {
  @include themify($themes) {
    background-image: themed('umaIcon');
  }
}

.sand__icon {
  @include themify($themes) {
    background-image: themed('sandIcon');
  }
}

.oxt__icon {
  @include themify($themes) {
    background-image: themed('oxtIcon');
  }
}

.band__icon {
  @include themify($themes) {
    background-image: themed('bandIcon');
  }
}

.cro__icon {
  @include themify($themes) {
    background-image: themed('croIcon');
  }
}

.amp__icon {
  @include themify($themes) {
    background-image: themed('ampIcon');
  }
}

.lrc__icon {
  @include themify($themes) {
    background-image: themed('lrcIcon');
  }
}

.ocean__icon {
  @include themify($themes) {
    background-image: themed('oceanIcon');
  }
}

.fet__icon {
  @include themify($themes) {
    background-image: themed('fetIcon');
  }
}

.bal__icon {
  @include themify($themes) {
    background-image: themed('balIcon');
  }
}

.knc__icon {
  @include themify($themes) {
    background-image: themed('kncIcon');
  }
}

.nmr__icon {
  @include themify($themes) {
    background-image: themed('nmrIcon');
  }
}

.ht__icon {
  @include themify($themes) {
    background-image: themed('htIcon');
  }
}

.nexo__icon {
  @include themify($themes) {
    background-image: themed('nexoIcon');
  }
}

.ftm__icon {
  @include themify($themes) {
    background-image: themed('ftmIcon');
  }
}

.cel__icon {
  @include themify($themes) {
    background-image: themed('celIcon');
  }
}

.mana__icon {
  @include themify($themes) {
    background-image: themed('manaIcon');
  }
}

.paxg__icon {
  @include themify($themes) {
    background-image: themed('paxgIcon');
  }
}

.algo__icon {
  @include themify($themes) {
    background-image: themed('algoIcon');
  }
}

.topbar__navigation {
  @include directify($directions) {
    #{directed('right')}: 0px;
    #{directed('margin-right')}: 15px;
  }

  display: none;
  height: 100%;

  @media screen and (max-width: 768px) {
    margin: 9px 0 9px 10px;
  }
}

.topbar__left {
  @include directify($directions) {
    #{directed('left')}: 0px;
  }

  flex: 1;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin: 9px 0 9px 10px;
  }
}

.topbar__avatar {
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
}

.topbar__avatar-img,
.topbar__avatar-name,
.topbar__icon {
  margin: auto 0;
}

.topbar__avatar-img {
  border-radius: 50%;
  height: 36px;
  width: 36px;
  filter: contrast(0.1);
}

.topbar__avatar-name {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  display: none;

  @include themify($themes) {
    color: themed('topbarLinkTitleColor') !important;
  }

  @include directify($directions) {
    #{directed('margin-left')}: 10px;
  }

  @include themify($themes) {
    color: themed('colorText');
  }
}

.topbar__icon {
  @include directify($directions) {
    #{directed('margin-left')}: 8px;
  }

  height: 18px;
  margin-top: auto;
  fill: #b1c3c8;
}

.topbar__menu {
  width: 200px;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;

  @include themify($themes) {
    background: themed('colorBackground');
  }

  button {
    padding: 0;

    &:hover {
      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
  }

  *:focus {
    outline: none;
  }
}

.topbar__menu-wrap {
  z-index: 101;
  position: absolute;
  width: 100%;
  min-width: 210px;

  @include directify($directions) {
    #{directed('right')}: 0px;
  }
}

.topbar__menu-theme-switch {
  padding: 9px 0 9px 20px;
}

.topbar__link {
  display: flex;
  padding: 9px 0 9px 20px;
  transition: all 0.3s;
  width: 100%;
  position: relative;
  cursor: pointer;

  &_active {
    .topbar__link-title {
      color: $color-accent;
    }
  }

  @include themify($themes) {
    color: themed('colorText');
  }

  &:before {
    content: "";
    position: absolute;

    @include directify($directions) {
      #{directed('left')}: 0px;
    }

    top: 0;
    height: 100%;
    width: 2px;
    background: $color-accent;
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    @include themify($themes) {
      color: themed('menuItemColorHover') !important;
      background-color: themed('colorHover');
    }

    &:before {
      opacity: 1;
    }
  }
}

.topbar__link-title {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 85%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;

  @include themify($themes) {
    color: themed('topbarLinkTitleColor') !important;
  }
}

.topbar__link-close_btn {
  height: 32px;
  position: absolute;
  right: 10%;
  width: 10%;
  cursor: pointer;
}

.topbar__link-icon {
  @include directify($directions) {
    #{directed('margin-right')}: 10px;
  }

  font-size: 13px;
  line-height: 13px;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.topbar__menu-divider {
  margin: 15px 0;

  @include themify($themes) {
    border-top: 1px solid themed('colorBorder');
  }
}

.topbar__profile_wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0px;
  gap: 32px;
  height: 60px;
}

.topbar_logout_img {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;

  @include themify($themes) {
    background-image: themed('logoutImg');
  }
}

.topbar__profile_divider {
  width: 0px;
  height: 20px;
  // margin-left: 32px;

  @include themify($themes) {
    // border: 1px solid #DDE4ED;
    border: 1px solid themed('reverseTextColor');
  }
}

.topbar__profile {
  margin-bottom: 0;

  @include directify($directions) {
    // #{directed('margin-left')}: 20px;
  }

  @media screen and (max-width: 640px) {
    @include directify($directions) {
      #{directed('margin-left')}: 0px;
    }
  }

  position: relative;
}

.topbar__collapse {
  position: relative;
  display: block;

  &.topbar__collapse--language {
    min-width: 70px;
    display: block;

    &>button {
      padding: 0 4px;
      width: 100%;
    }
  }

  @media screen and (min-width: 568px) {
    display: block;
  }
}

.topbar__collapse-content {
  width: 270px;
  position: absolute;

  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  bottom: 20px;
  transform: translateY(100%);
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  z-index: 101;

  @include themify($themes) {
    background: themed('colorBackground');
  }

  &.topbar__collapse-content--language {
    max-width: 75px;
    padding: 10px 0;
    bottom: 0;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    @include directify($directions) {
      #{directed('left')}: 50%;
    }

    transform: translate(-50%, 100%);
  }

  @media screen and (min-width: 520px) {
    width: 330px;
  }
}

.topbar__language-btn {
  padding: 4px 15px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;

  @include directify($directions) {
    text-align: directed('left');
  }

  font-size: 13px;
  line-height: 16px;
  transition: 0.3s;

  &:hover {
    color: $color-accent;
  }
}

.topbar__language-btn-title {
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:not(:last-child) {
    @include directify($directions) {
      #{directed('margin-right')}: 5px;
    }
  }

  img {
    height: 11px;
    width: 16px;

    @include directify($directions) {
      #{directed('margin-right')}: 4px;
    }
  }
}

.topbar__back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}

.topbar__collapse-title-wrap {
  padding: 20px 15px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.topbar__collapse-item {
  padding-top: 12px;
  padding-bottom: 12px;

  @include directify($directions) {
    #{directed('padding-left')}: 70px;
    #{directed('padding-right')}: 55px;
  }

  display: flex;
  position: relative;
  height: 62px;
  align-items: center;
  flex-wrap: wrap;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }
}

.topbar__collapse-img-wrap {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;

  @include directify($directions) {
    #{directed('left')}: 15px;
  }
}

.topbar__collapse-message {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: $color-gray;

  @include directify($directions) {
    text-align: directed('left');
  }

  &.topbar__collapse-message--mail {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.topbar__collapse-name {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: $color-accent;
}

.topbar__collapse-date {
  position: absolute;
  top: 12px;

  @include directify($directions) {
    #{directed('right')}: 15px;
  }

  font-size: 10px;
  color: $color-gray;
  margin-top: 2px;
}

.topbar__collapse-link {
  display: block;
  padding: 10px;
  text-transform: uppercase;
  color: $color-accent;
  transition: 0.3s;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;

  &:hover {
    color: $color-accent-hover;
  }
}

.topbar__collapse-title {
  font-size: 14px;
  line-height: 16px;
}

.topbar__collapse-button {
  color: #c5d2d6;
  border: none;
  padding: 0;

  @include directify($directions) {
    text-align: directed('right');
  }

  font-size: 12px;
  line-height: 16px;
  transition: 0.3s;
  background: transparent;

  &:hover {
    color: $color-accent;
  }
}

.topbar__btn {
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:hover {

    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: #b1c3c8;
  }

  @media screen and (max-width: 640px) {
    padding: 0 5px;
  }

  &.topbar__btn--new {

    .topbar__btn-new-label {
      position: absolute;

      @include directify($directions) {
        #{directed('right')}: 9px;
      }

      top: 20px;

      &>div {
        position: relative;

        &:before {
          background-color: rgba(224, 83, 111, 0.2);
          content: "";
          position: absolute;
          top: 50%;

          @include directify($directions) {
            #{directed('left')}: 50%;
          }

          border-radius: 50%;
          animation: beforePulse 1.5s infinite;

          @include directify($directions) {
            transform: #{directed('translate')};
          }
        }

        &:after {
          height: 7px;
          width: 7px;
          background-color: #e0536f;
          content: "";
          position: absolute;
          top: 50%;

          @include directify($directions) {
            #{directed('left')}: 50%;
          }

          border-radius: 50%;

          @include directify($directions) {
            transform: #{directed('translate')};
          }
        }
      }
    }
  }

  @keyframes beforePulse {
    from {
      width: 7px;
      height: 7px;
    }

    25% {
      width: 13px;
      height: 13px;
    }

    to {
      width: 7px;
      height: 7px;
    }
  }
}

.topbar__nav {
  width: 100%;
  display: none;
  height: 100%;
  justify-content: center;
  align-items: center;

  @include directify($directions) {
    #{directed('margin-right')}: 140px;
  }
}

.topbar .topbar__nav-dropdown-toggle {
  height: 60px;
  background: transparent;
  border-radius: 0;
  border: none;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:before {
    display: none;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus:active {

    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }

  svg {
    fill: $color-additional;

    @include directify($directions) {
      #{directed('margin-left')}: 3px;
    }

    height: 16px;
    width: 16px;

    @include directify($directions) {
      #{directed('margin-right')}: 0px;
    }
  }
}

.topbar__nav-dropdown-menu {
  width: 240px;
  border-top: 2px solid $color-accent;

  button {
    padding: 0;
  }
}

.topbar__link-badge {
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;

  @include directify($directions) {
    #{directed('margin-left')}: 5px;
  }

  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;

  span {
    position: absolute;

    @include directify($directions) {
      #{directed('left')}: 0px;
    }

    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.topbar__nav-link {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  transition: 0.3s;
  font-size: 14px;

  @include themify($themes) {
    color: themed('colorText');
  }

  &-active {
    border-bottom: 3px solid $color-accent;

    span {
      color: $color-accent;
      margin-top: 3px;
    }
  }

  &:hover {

    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }
}

.MuiButtonBase-root.topbar__nav-menu-icon {
  @include themify($themes) {
    color: themed('colorText');
  }

  display: flex;
  margin-left: 0px;
}

.topbar__drawer {
  display: flex;
  min-width: 240px;
  flex-direction: column;
}

.topbar__category-wrap {
  position: relative;

  &:hover {

    .topbar__submenu {
      opacity: 1;
      width: auto;
      height: auto;
    }
  }
}

.topbar__category-icon {
  position: absolute;

  @include directify($directions) {
    #{directed('right')}: 20px;
  }

  font-size: 10px;
  line-height: 14px;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.rtl-support {
  .topbar__submenu {
    transform: none;
    right: 100%;
  }

  .topbar__category-icon {
    transform: rotate(180deg);
  }
}

.ltr-support {
  .topbar__submenu {
    right: 1px;
  }
}

.topbar__submenu {
  position: absolute;
  top: 0;
  transform: translateX(100%);
  transition: 0.3s;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;

  @include themify($themes) {
    background-color: themed('colorHover');
  }

  .topbar__link {

    &:hover {

      @include themify($themes) {
        color: themed('menuItemColorHover') !important;
        background-color: themed('colorBackground');
      }
    }
  }
}

.topbar__search {
  display: flex;
  margin: auto 0;
  padding: 0;
  position: relative;
}

.topbar__search-field {
  width: 0;
  transition: all 0.3s;
  opacity: 0;

  @include directify($directions) {
    #{directed('margin-right')}: auto;
    #{directed('margin-left')}: 0px;
  }

  margin-top: auto;
  margin-bottom: auto;
  border: none;
  border-radius: 13px;
  height: 26px;

  @include directify($directions) {
    #{directed('padding-left')}: 10px;
    #{directed('padding-right')}: 46px;
  }

  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
  }

  &.topbar__search-field--open {
    width: 100%;
    max-width: 200px;
    min-width: 50px;
    opacity: 1;
    z-index: 102;

    @include directify($directions) {
      #{directed('margin-left')}: 10px;
    }

    &+button {
      @include directify($directions) {
        #{directed('right')}: 10px;
      }
    }
  }

  &:focus {
    outline: none;
  }
}


.layout--top-navigation {
  .topbar__search-field {
    &::placeholder {
      color: rgb(202, 193, 193);
    }

    &.topbar__search-field--open {
      width: 55vw;

      @media screen and (max-width: 1200px) {
        width: 59vw;
      }

      @media screen and (max-width: 1049px) {
        width: 20vw;
      }
    }
  }
}

.topbar__search-btn {
  z-index: 1001;
  height: 26px;
  width: 26px;
  border-radius: 13px;
  border: none;
  padding: 0;
  background: transparent;
  position: absolute;

  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  &:hover {

    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }
  }
}

@media screen and (min-width: 480px) {
  .topbar__menu {
    width: 100%;

    @include directify($directions) {
      #{directed('left')}: 0px !important;
    }
  }
}

@media screen and (min-width: 576px) {

  .topbar__button {

    &.topbar__button--desktop {
      display: block;
    }

    &.topbar__button--mobile {
      display: none;
    }
  }

  .topbar.topbar--navigation {

    .topbar__button.topbar__button--mobile {
      display: block;
    }
  }
}

@media screen and (min-width: 768px) {

  .topbar__search {
    display: flex;
  }

  .topbar__navigation {
    display: flex;
  }

  .MuiButtonBase-root.topbar__nav-menu-icon,
  .topbar__nav-menu-icon {
    display: none;
  }
}

@media screen and (min-width: 930px) {
  .topbar__nav {
    display: flex;
  }

  .topbar.topbar--navigation {

    .topbar__logo {
      @include directify($directions) {
        #{directed('margin-left')}: 15px;
      }

      display: block;
    }

    .topbar__logo_okcoin {
      @include directify($directions) {
        #{directed('margin-left')}: 15px;
      }

      display: block;
    }

    .topbar__logo_okcoin {
      @include directify($directions) {
        #{directed('margin-left')}: 15px;
      }

      display: block;
    }

    .topbar__logo_finoa {
      @include directify($directions) {
        #{directed('margin-left')}: 15px;
      }

      display: block;
    }

    .topbar__button.topbar__button--mobile {
      display: none;
    }

    .topbar__profile {
      @include directify($directions) {
        #{directed('margin-left')}: 0px;
      }
    }
  }
}

@media screen and (min-width: 1060px) {
  .topbar.topbar--navigation {
    .topbar__avatar-name {
      display: none;
    }
  }
}

@media screen and (min-width: 1061px) {
  .topbar.topbar--navigation {
    .topbar__avatar-name {
      display: block;
    }
  }
}

@media screen and (min-width: 1580px) {

  .topbar__nav-dropdown-toggle {
    width: 240px;
  }
}