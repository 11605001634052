a {
  text-decoration: none;
}

table, tr, td {
  background: transparent;
}

.table > :not(caption) > * > * {
  background-color: transparent !important;
}

legend {
  float: none;
}