$themes: (
  light: (colorBackgroundFilters: #41424a,
    colorBackground: #FFFFFF,
    colorBackgroundBody: #F6F8FB,
    inboxButtonBackground: #f2f4f7,
    inboxButtonBackgroundHover: darken(#f2f4f7, 5%),
    marketoverviewExchangeColorBackground: #eff1f5,
    marketoverviewGroupColorBackground: #F6F8FB,
    draggableHeaderBackground: #888888,
    colorHeaderDataPicker:#6db0ff,
    colorText: #646777,
    bgColorText: #646777,
    colorTextAdditional: #646777,
    logoImgOkcoin: url(../shared/img/logo/Steubing_Logo_DLT_Plattform_invert.png),
    logoImgFinoa: url(../shared/img/logo/logo_finoa_l.svg),
    logoImg: url(../shared/img/logo/main_logo_dark.png),
    formLogo: url(../shared/img/logo/main_logo_dark.png),
    connectionImg: url(../shared/img/shuffle_l.svg),
    settingsImg: url(../shared/img/settings_l.svg),
    logoutImg: url(../shared/img/log-out.svg),
    tradeHistoryGreenImg: url(../shared/img/trade_history_green.svg),
    tradeHistoryRedImg: url(../shared/img/trade_history_red.svg),
    alertTriangleImg: url(../shared/img/alert-triangle.svg),
    alertInfoleImg: url(../shared/img/info.svg),
    alertSuccessImg: url(../shared/img/check-circle.svg),
    alertWarningImg: url(../shared/img/alert-circle.svg),
    userPlusImg: url(../shared/img/user-plus_l.svg),
    chevronLeftImg: url(../shared/img/chevron-left_l.svg),
    chevronLeftEndImg: url(../shared/img/chevron-left-end_l.svg),
    chevronRightImg: url(../shared/img/chevron-right_l.svg),
    chevronRightEndImg: url(../shared/img/chevron-right-end_l.svg),
    plusImg: url(../shared/img/plus_l.svg),
    minusImg: url(../shared/img/minus_l.svg),
    btcIcon: url(../shared/img/currencies/btc.svg),
    bchIcon: url(../shared/img/currencies/bch.svg),
    bnbIcon: url(../shared/img/currencies/bnb.svg),
    bnbBscIcon: url(../shared/img/currencies/bnb.svg),
    cethIcon: url(../shared/img/currencies/ceth.svg),
    daiIcon: url(../shared/img/currencies/dai.svg),
    dotIcon: url(../shared/img/currencies/dot.svg),
    enjIcon: url(../shared/img/currencies/enj.svg),
    ethIcon: url(../shared/img/currencies/eth.svg),
    linkIcon: url(../shared/img/currencies/link.svg),
    ltcIcon: url(../shared/img/currencies/ltc.svg),
    mkrIcon: url(../shared/img/currencies/mkr.svg),
    snxIcon: url(../shared/img/currencies/snx.svg),
    stethIcon: url(../shared/img/currencies/steth.svg),
    maticIcon: url(../shared/img/currencies/matic.svg),
    uniIcon: url(../shared/img/currencies/uni.svg),
    usdcIcon: url(../shared/img/currencies/usdc.svg),
    usdtIcon: url(../shared/img/currencies/usdt.svg),
    xlmIcon: url(../shared/img/currencies/xlm.svg),
    xrpIcon: url(../shared/img/currencies/xrp.svg),
    compIcon: url(../shared/img/currencies/comp.svg),
    etcIcon: url(../shared/img/currencies/etc.svg),
    solIcon: url(../shared/img/currencies/sol.svg),
    aaveIcon: url(../shared/img/currencies/aave.svg),
    grtIcon: url(../shared/img/currencies/grt.svg),
    crvIcon: url(../shared/img/currencies/crv.svg),
    batIcon: url(../shared/img/currencies/bat.svg),
    yfiIcon: url(../shared/img/currencies/yfi.svg),
    sushiIcon: url(../shared/img/currencies/sushi.svg),
    bntIcon: url(../shared/img/currencies/bnt.svg),
    zrxIcon: url(../shared/img/currencies/zrx.svg),
    umaIcon: url(../shared/img/currencies/uma.svg),
    sandIcon: url(../shared/img/currencies/sand.svg),
    oxtIcon: url(../shared/img/currencies/oxt.svg),
    bandIcon: url(../shared/img/currencies/band.svg),
    ampIcon: url(../shared/img/currencies/amp.svg),
    lrcIcon: url(../shared/img/currencies/lrc.svg),
    balIcon: url(../shared/img/currencies/bal.svg),
    kncIcon: url(../shared/img/currencies/knc.svg),
    nmrIcon: url(../shared/img/currencies/nmr.svg),
    htIcon: url(../shared/img/currencies/ht.svg),
    nexoIcon: url(../shared/img/currencies/nexo.svg),
    manaIcon: url(../shared/img/currencies/mana.svg),
    paxgIcon: url(../shared/img/currencies/paxg.svg),
    // AXS, CHZ, 1INCH, AVAX, CRO, OCEAN, FET, FTM, CEL, FTT, PERP, MIR, - without icons
    priceLadderBorderColor: #F6F8FB,
    colorHover: #fafbfe,
    alertTextColor: #1D2129,
    clientSearchTextColor: #7F8A95,
    menuItemColorHover: #32C6A1,
    reverseTextColor: #1D2129,
    reverseInnerTextColor: #7F8A95,
    topbarLinkTitleColor: #1D2129,
    colorFolderHover: #f0eeee,
    colorBorder: #DDE4ED,
    colorIcon: #dddddd,
    imgInvert: invert(0%),
    colorFieldsBorder: #f2f4f7,
    colorFieldsBorderSelect: #33333a,
    colorFieldsBorderSelectFilters: #33333a,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
    colorEmoji: #232329,
    sidebarColor: aliceblue,
    inputBgColor: white,
    orderTabsColor: #eff1f5,
    placeOrdeerButtonBGColor: #1D2129,
    placeOrdeerButtonColor: #FFFFFF,
    tradeHistoryTitleColor: #1D2129,
    balancesTheadBg: #F6F8FB,
    ordersCancelBtnBg: #DDE4ED,
    ordersCancelBtnColor: #1D2129,
    marketOverviewBGColor: #F6F8FB,
    rfqBlockGBColor: #FFFFFF,
    chechedFilterColor: #787985,
    chechedFilterColorHover: #d8dfe9,
  ),
  dark: (colorBackgroundFilters: #41424a,
    colorBackground: #232329,
    mainButtonColor: #1D2129,
    mainButtonBGColor: #F6F8FB,
    mainButtonBGHoverColor: #EFF3F4,
    mainButtonBGDisabledColor: #f5f5f5,
    colorBackgroundBody: #2a2a31,
    inboxButtonBackground: #2a2a31,
    inboxButtonBackgroundHover: lighten(#2a2a31, 5%),
    marketoverviewExchangeColorBackground: #999999,
    marketoverviewGroupColorBackground: #31353C,
    draggableHeaderBackground: #6c757d,
    colorHeaderDataPicker:#063263,
    colorText: #dddddd,
    bgColorText: #646777,
    colorTextAdditional: #999999,
    logoImgOkcoin: url(../shared/img/logo/Steubing_Logo_DLT_Plattform_invert.png),
    logoImgFinoa: url(../shared/img/logo/logo_finoa.svg),
    logoImg: url(../shared/img/logo/main_logo_light.png),
    formLogo: url(../shared/img/logo/main_logo_light.png),
    connectionImg: url(../shared/img/shuffle.svg),
    settingsImg: url(../shared/img/settings.png),
    logoutImg: url(../shared/img/log-out.svg),
    tradeHistoryGreenImg: url(../shared/img/trade_history_green.svg),
    tradeHistoryRedImg: url(../shared/img/trade_history_red.svg),
    alertTriangleImg: url(../shared/img/alert-triangle.svg),
    alertInfoleImg: url(../shared/img/info.svg),
    alertSuccessImg: url(../shared/img/check-circle.svg),
    alertWarningImg: url(../shared/img/alert-circle.svg),
    userPlusImg: url(../shared/img/user-plus.svg),
    chevronLeftImg: url(../shared/img/chevron-right.svg),
    chevronLeftEndImg: url(../shared/img/chevron-right-end.svg),
    chevronRightImg: url(../shared/img/chevron-left.svg),
    chevronRightEndImg: url(../shared/img/chevron-left-end.svg),
    plusImg: url(../shared/img/plus.svg),
    minusImg: url(../shared/img/minus.svg),
    btcIcon: url(../shared/img/currencies/btc.svg),
    bchIcon: url(../shared/img/currencies/bch.svg),
    bnbIcon: url(../shared/img/currencies/bnb.svg),
    bnbBscIcon: url(../shared/img/currencies/bnb.svg),
    cethIcon: url(../shared/img/currencies/ceth.svg),
    daiIcon: url(../shared/img/currencies/dai.svg),
    dotIcon: url(../shared/img/currencies/dot.svg),
    enjIcon: url(../shared/img/currencies/enj.svg),
    ethIcon: url(../shared/img/currencies/eth.svg),
    linkIcon: url(../shared/img/currencies/link.svg),
    ltcIcon: url(../shared/img/currencies/ltc.svg),
    mkrIcon: url(../shared/img/currencies/mkr.svg),
    snxIcon: url(../shared/img/currencies/snx.svg),
    stethIcon: url(../shared/img/currencies/steth.svg),
    maticIcon: url(../shared/img/currencies/matic.svg),
    uniIcon: url(../shared/img/currencies/uni.svg),
    usdcIcon: url(../shared/img/currencies/usdc.svg),
    usdtIcon: url(../shared/img/currencies/usdt.svg),
    xlmIcon: url(../shared/img/currencies/xlm.svg),
    xrpIcon: url(../shared/img/currencies/xrp.svg),
    compIcon: url(../shared/img/currencies/comp.svg),
    etcIcon: url(../shared/img/currencies/etc.svg),
    solIcon: url(../shared/img/currencies/sol.svg),
    aaveIcon: url(../shared/img/currencies/aave.svg),
    grtIcon: url(../shared/img/currencies/grt.svg),
    crvIcon: url(../shared/img/currencies/crv.svg),
    batIcon: url(../shared/img/currencies/bat.svg),
    yfiIcon: url(../shared/img/currencies/yfi.svg),
    sushiIcon: url(../shared/img/currencies/sushi.svg),
    bntIcon: url(../shared/img/currencies/bnt.svg),
    zrxIcon: url(../shared/img/currencies/zrx.svg),
    umaIcon: url(../shared/img/currencies/uma.svg),
    sandIcon: url(../shared/img/currencies/sand.svg),
    oxtIcon: url(../shared/img/currencies/oxt.svg),
    bandIcon: url(../shared/img/currencies/band.svg),
    ampIcon: url(../shared/img/currencies/amp.svg),
    lrcIcon: url(../shared/img/currencies/lrc.svg),
    balIcon: url(../shared/img/currencies/bal.svg),
    kncIcon: url(../shared/img/currencies/knc.svg),
    nmrIcon: url(../shared/img/currencies/nmr.svg),
    htIcon: url(../shared/img/currencies/ht.svg),
    nexoIcon: url(../shared/img/currencies/nexo.svg),
    manaIcon: url(../shared/img/currencies/mana.svg),
    paxgIcon: url(../shared/img/currencies/paxg.svg),
    priceLadderBorderColor: #31353C,
    colorHover: #58575F,
    alertTextColor: #1D2129,
    clientSearchTextColor: #ADB6C0,
    menuItemColorHover: #32C6A1,
    reverseTextColor: #DDE4ED,
    reverseInnerTextColor: #ADB6C0,
    topbarLinkTitleColor: #FFFFFF,
    colorFolderHover: #ffffff1A,
    colorBorder: #5D666F,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorderSelect: #f2f4f7,
    colorFieldsBorderSelectFilters: #33333a,
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    colorEmoji: #ffffff,
    sidebarColor: #232329,
    inputBgColor: #41424b,
    orderTabsColor: #333246,
    placeOrdeerButtonBGColor: #F6F8FB,
    placeOrdeerButtonColor: #1D2129,
    tradeHistoryTitleColor: #ADB6C0,
    balancesTheadBg: #31353C,
    ordersCancelBtnBg: #DDE4ED,
    ordersCancelBtnColor: #31353C,
    marketOverviewBGColor: #31353C,
    rfqBlockGBColor: #28282f,
    chechedFilterColor: #f6da6e,
    chechedFilterColorHover: #ffc107,
  )
);

@mixin themify($themes) {

  @each $theme,
  $map in $themes {
    .theme-#{$theme} & {
      $theme-map: (
        ) !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}'
        );
      $theme-map: map-merge($theme-map, ($key: $value)) !global;
    }

    @content;
    $theme-map: null !global;
  }
}
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

// $color-accent: #4ce1b6;
$color-accent: #32C6A1;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

// $color-red: #ff4861;
$color-red: #F06158;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);


$directions: (
  ltr: (direction: ltr,

    right: right,
    left: left,

    margin-right: 'margin-right',
    margin-left: 'margin-left',

    padding-left: 'padding-left',
    padding-right: 'padding-right',

    border-top-left-radius: 'border-top-left-radius',
    border-bottom-left-radius: 'border-bottom-left-radius',

    border-top-right-radius: 'border-top-right-radius',
    border-bottom-right-radius: 'border-bottom-right-radius',

    border-left: 'border-left',
    border-right: 'border-right',

    translate: 'translate(-50%, -50%);',
    mirrorY: 'scale(1, 1)',

    border-right-color: 'border-right-color',

    transform-sidebar: 'rotate(90deg)',

    flex-flow: row nowrap,
    row: 'row',

    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(0%)),
    '-': '-',
  ),
  rtl: (direction: rtl,

    right: left,
    left: right,

    margin-right: 'margin-left',
    margin-left: 'margin-right',

    padding-left: 'padding-right',
    padding-right: 'padding-left',

    border-top-left-radius: 'border-top-right-radius',
    border-bottom-left-radius: 'border-bottom-right-radius',

    border-top-right-radius: 'border-top-left-radius',
    border-bottom-right-radius: 'border-bottom-left-radius',

    border-left: 'border-right',
    border-right: 'border-left',

    translate: 'translate(50%, -50%);',
    mirrorY: 'scale(1, -1)',

    border-right-color: 'border-left-color',
    transform-sidebar: 'rotate(-90deg)',

    flex-flow: row-reverse nowrap,
    row: 'row-reverse',

    sidebar-close: translateX(0),
    sidebar-no-desktop: translateX(calc(100%)),
    '-': '+',
  )
);

@mixin directify($directions) {

  @each $direction,
  $map in $directions {
    .#{$direction}-support & {
      $direction-map: (
        ) !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($directions, $direction), '#{$key}'
        );
      $direction-map: map-merge($direction-map, ($key: $value)) !global;
    }

    @content;
    $direction-map: null !global;
  }
}
}

@function directed($key) {
  @return map-get($direction-map, $key);
}